<template>
<!--  <a-spin :spinning="spinning">-->
  <div class="w-full bg-white rounded-md sm:my-4">
    <div class="card-top px-4 mb-2 flex justify-between items-center border-b border-gray-550">
      <div class="card-title flex items-center">
        <span class="anticon w-10 h-10">
            <font-awesome-icon icon="shopping-basket" class="text-blue-500 text-base h-10"/>
        </span>
        <span class="text-lg font-medium ml-3">{{ $t('user.live.productBlock.products') }}</span>
      </div>
    </div>
    <div class="overflow-y-auto height-vh">

      <div class="w-full py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-if="this.$store.state.userProducts.length === 0">
        <p class="text-darkwhite-950">{{ $t('user.live.productBlock.no-product') }}</p>
      </div>
      <div  class="w-full py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-for="product in this.$store.state.userProducts" :key="product.id">
        <div class="w-3/12 mr-2">
          <img :src="product.image_urls"  style="max-height: 70px" alt="">
        </div>
        <div class="w-8/12">
          <div class="pro-title text-base font-normal">{{product.title}}</div>
          <div class="pro-desc text-xs pt-1 pb-3">
            {{product.description}}
          </div>
          <div class="pro-price">
            <span class="text-base">{{product.discount_price}} TL</span> <span class="text-xs text-gray-300 line-through">{{product.price}} TL</span>
          </div>
        </div>
        <div class="w-full mt-2 flex items-center justify-between">
          <a-button
              v-if="product.is_sending_metadata == false"
              type="primary"
              class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 w-2/5 px-2 py-1 h-8 rounded-md"
              style="font-size: 9px"
              @click="sendProduct(product)"
          >
            {{ $t('user.live.productBlock.publish-product') }}
          </a-button>
          <div v-else class="w-full">
            <a-button
                type="primary"
                class="bg-red-500 border-red-500 font-normal text-xs text-white hover:text-white hover:bg-red-600  px-5 py-1 h-8 rounded-md"
                style="font-size: 9px"

                @click="removeProductWithMetadata(product)"

            >
              {{ $t('user.live.productBlock.remove-product') }}
            </a-button>
            <a-button
                v-if="this.highlightProductId !== product.id"
                type="primary"
                class="bg-cyan-700 border-cyan-700 font-normal text-xs text-white hover:text-white w-2/5 px-2 py-1 h-8 rounded-md ml-2"
                @click="highlightProductWithMetadata(product)"
                style="font-size: 9px"


            >
              {{ $t('user.live.productBlock.put-forward') }}
            </a-button>
          </div>

        </div>
        <div class="w-full mb-2 flex  justify-start">
          <div class="text-blue-500 flex items-center" style="font-size: 10px"  title="Tıklanma">
              <span class="anticon w-10 h-auto">
                  <font-awesome-icon icon="location-arrow" class="text-blue-500 text-base h-10"/>
              </span>
            Tıklanma:
            {{product.statistics.click > 0 ? product.statistics.click:0}}
          </div>
          <div class="text-blue-500 flex items-center" style="font-size: 10px"  title="Stok">
                <span class="anticon w-10 h-auto" >
                      <font-awesome-icon icon="boxes" class="text-blue-500 text-base h-10"/>
                </span>Stok:
            {{product.statistics.stock > 0 ? product.statistics.stock:0}}
          </div>
          <div class="text-blue-500 flex items-center" v-if="this.highlightProductId === product.id && product.is_sending_metadata == true">
              <span class="anticon w-10 h-auto">
                  <font-awesome-icon icon="star" class="text-blue-500 text-base h-10"/>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  </a-spin>-->

</template>

<script>
export default {
  name: "Products",
  data(){
    return{
      spinning:true,
      userProducts:[],
      highlightProductId:0
    }
  },
  beforeCreate() {
    let url ='';
    let token = '';
    if (this.$store.state.layout !=='streamer'){
      url = '/getEventProducts'
      token = localStorage.getItem("token")
    }else {
      url = '/streamer/getEventProducts'
      token = localStorage.getItem("streamerToken")
    }
    this.$appAxios.get(url+'?eventId='+this.$route.params.id,{
      headers: {
        Authorization: [`Bearer ${token}`]
      }
    }).then((response)=>{
      console.log("products")
      console.log(response.data.data.products)
      this.$store.state.userProducts = response.data.data.products
      this.spinning = false
    });
  },
  methods:{
    sendProduct(product){
      this.$appAxios.post('/addProductWithMetadata',{
        'eventId':this.$route.params.id,
        'eventProductId':product.id
      },{
        headers: {
          Authorization: [`Bearer ${localStorage.token}`]
        }
      }).then(()=>{
        product.is_sending_metadata = true
      }).catch(()=>{
        this.$swal({
          icon: 'error',
          text: this.$t('user.live.productBlock.publishing-failed')
        })
      });
    },
    removeProductWithMetadata(product){
      this.$appAxios.post('/removeProductWithMetadata',{
        'eventId':this.$route.params.id,
        'eventProductId':product.id
      },{
        headers: {
          Authorization: [`Bearer ${localStorage.token}`]
        }
      }).then(()=>{
        product.is_sending_metadata = false
        this.highlightProductId = 0
      }).catch(()=>{
        this.$swal({
          icon: 'error',
          text: this.$t('user.live.productBlock.removing-failed')
        })
      });
    },
    highlightProductWithMetadata(product){
      this.$appAxios.post('/highlightProductWithMetadata',{
        'eventId':this.$route.params.id,
        'eventProductId':product.id
      },{
        headers: {
          Authorization: [`Bearer ${localStorage.token}`]
        }
      }).then(()=>{
        this.highlightProductId= product.id
      }).catch(()=>{
        this.$swal({
          icon: 'error',
          text: this.$t('user.live.productBlock.put-forward-failed')
        })
      });
    },
  }
}
</script>

<style scoped>

</style>