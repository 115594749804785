<template>
  <a-spin class="sm:mb-4" :spinning="spinning">
    <div class="w-full bg-white rounded-md">
      <div class="card-top px-4 mb-2 flex justify-between items-center border-b border-gray-550">
        <div class="card-title flex items-center">
                <span class="anticon w-10 h-10">
                    <font-awesome-icon icon="shopping-basket" class="text-blue-500 text-base h-10"/>
                </span>
          <span class="text-lg font-medium ml-3">{{$t('user.live.campaignBlock.campaigns')}}</span>
        </div>
      </div>
      <div class="h-scroll overflow-y-auto height-vh">
        <div class="w-full py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-if="this.userCampaigns.length === 0">
          <p class="text-darkwhite-950">{{ $t('user.live.campaignBlock.no-campaigns') }}</p>
        </div>
        <div  class="w-full py-3 border-b border-gray-550 flex flex-wrap items-center px-4" v-for="campaign in this.userCampaigns" :key="campaign.id">
          <div class="w-3/12 mr-2">
            <img :src="campaign.image_urls" alt="">
          </div>
          <div class="w-8/12">
            <div class="pro-title text-base font-normal">{{campaign.title}}</div>
            <div class="pro-desc text-xs pt-1 pb-3">
              {{
                (campaign.description.length > 100) ? campaign.description.substr(0, 100-1) + '&hellip;' : campaign.description
              }}
            </div>
            <div class="pro-desc text-xs pt-1 pb-3">
              {{$t('user.live.campaignBlock.campaign-code')+': '+campaign.code}}
            </div>
          </div>
          <div class="w-full my-2 flex items-center justify-between">
            <a-button
                type="primary"
                class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 w-2/5 px-2 py-1 h-8 rounded-md"
                @click="sendCampaign(campaign)"
                style="font-size: 9px"

            >
              {{ $t('user.live.campaignBlock.publish-campaign') }}
            </a-button>
            <a-button
                v-if="campaign.is_sending_metadata"
                type="primary"
                class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 w-2/5 px-2 py-1 h-8 rounded-md"
                @click="removeCampaign(campaign)"
                style="font-size: 9px"

            >
              {{ $t('user.live.campaignBlock.remove-campaign') }}
            </a-button>
            <div class="text-blue-300 flex items-center">
<!--                        <span class="anticon w-10 h-auto">-->
<!--                            <font-awesome-icon icon="location-arrow" class="text-blue-300 text-base h-10"/>-->
<!--                        </span>-->
<!--              {{campaign.click > 0 ? campaign.click:0}}-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
export default {
  name: "Campaign",
  data(){
    return{
      spinning:true,
      userCampaigns:[]
    }
  },
  created() {
    let url ='';
    let token = '';
    if (this.$store.state.layout !=='streamer'){
      url = '/getEventCampaigns'
      token = localStorage.getItem("token")
    }else {
      url = '/streamer/getEventCampaigns'
      token = localStorage.getItem("streamerToken")
    }
    this.$appAxios.get(url+'?eventId='+this.$route.params.id,{
      headers: {
        Authorization: [`Bearer ${token}`]
      }
    }).then((response)=>{
      this.userCampaigns = response.data.data.eventCampaigns
      this.spinning = false
      console.log('campaign')

      console.log(response)
    });
  },methods:{
    sendCampaign(campaign){
      this.$appAxios.post('/addCampaignWithMetadata',{
        'eventId':this.$route.params.id,
        'campaignId':campaign.id
      },{
        headers: {
          Authorization: [`Bearer ${localStorage.token}`]
        }
      }).then(()=>{
        campaign.is_sending_metadata = true
        this.$swal({
          icon: 'success',
          text: this.$t('user.live.campaignBlock.publishing-success')
        })
      })
      //TODO burada oluşan hatalar handle edilmeli.
      /*.catch(()=>{
        this.$swal({
          icon: 'error',
          text: "Kampanya yayına eklenirken hata. "
        })
      });*/
    },
    removeCampaign(campaign){
      this.$appAxios.post('/removeCampaignWithMetadata',{
        'eventId':this.$route.params.id,
        'eventCampaignId':campaign.id
      },{
        headers: {
          Authorization: [`Bearer ${localStorage.token}`]
        }
      }).then(()=>{
        campaign.is_sending_metadata = false
        this.$swal({
          icon: 'success',
          text: this.$t('user.live.campaignBlock.removing-success')
        })
      }).catch(()=>{
        //TODO burada oluşan hatalar handle edilmeli.
        this.$swal({
          icon: 'error',
          text: this.$t('user.live.campaignBlock.removing-failed')
        })
      });
    }

  }
}
</script>

<style scoped>

</style>