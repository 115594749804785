<template>
  <div>
    <a-spin :spinning="spinEventInfo">
      <div class="live-title text-2xl mb-3 flex justify-between flex-wrap flex-col">
        <span>
          <span class="break-words sm:text-sm"> {{ this.stream.title }} </span>
        </span>
        <div class="my-2">
          <a-button
              type="primary"
              class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 sm:px-2 px-5 py-1 h-8 rounded-md mr-2"
              @click="frameVisible = true"
          >
            <font-awesome-icon icon="copy" class="mr-3" />
            İframe
          </a-button>
          <a-button
              type="primary"
              class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 sm:px-2 px-5 py-1 h-8 rounded-md mr-2"
              @click="copyLink"
          >
            <font-awesome-icon icon="copy" class="mr-3" />
            Yayın linkini kopyala
          </a-button>
          <a-button
              v-show="!this.live && !this.preview"
              type="primary"
              class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 sm:px-2 px-5 py-1 h-8 rounded-md mr-2"
              @click="showModal"
          >
            <font-awesome-icon icon="camera" class="mr-3" />
            {{ $t('user.live.screenBlock.open-camera') }}
          </a-button>
          <a-button
              v-show="!this.live && this.preview"
              type="primary"
              class="bg-blue-500 border-blue-500 font-normal text-xs text-white hover:text-white hover:bg-blue-600 sm:px-2 px-5 py-1 h-8 rounded-md"
              @click="goLive"
          >
            <font-awesome-icon icon="play" class="mr-3" />
            {{ $t('user.live.screenBlock.start-stream') }}
          </a-button>
          <a-button
            v-show="this.live"
            type="primary"
            class="bg-red-500 border-red-500 font-normal text-xs text-white hover:text-white hover:bg-red-600  px-5 py-1 h-8 rounded-md"
            @click="stopStream"

        >
          <font-awesome-icon icon="stop" class="mr-3" />

          {{ $t('user.live.screenBlock.stop-stream') }}
        </a-button>

        </div>

        <a-modal
            title="iframe"
            v-model:visible="frameVisible"
            :footer="null"
        >
          <pre> &lt;style type="text/css"&gt;</pre>
          <pre>   #onlivesale_live_screen {</pre>
          <pre>     display: block;</pre>
          <pre>     width: 100%;</pre>
          <pre>     border: none;</pre>
          <pre>     overflow-y: auto;</pre>
          <pre>     overflow-x: hidden;</pre>
          <pre>     min-height: 450px;</pre>
          <pre>     height: 100%;</pre>
          <pre>   }</pre>
          <pre> &lt;/style&gt;</pre>
          &lt;iframe id="onlivesale_live_screen" src="https://app.onlivesale.com/{{this.$route.params.id}}?client=iframe"&gt;&lt;/iframe&gt;
          <pre>&lt;script src="https://app.onlivesale.com/javascript/iframe.js"&gt;&lt;/script&gt;</pre>

          <pre>
      </pre>
        </a-modal>
      </div>
      <div class="live-title text-sm flex items-center" >
        <div class="flex items-center mr-3 sm:text-xs">
          <span class="anticon mr-2 relative" v-if="this.stream.date">
              <font-awesome-icon icon="calendar-alt" class="text-gray-300 text-xs"/>
          </span>
          {{ this.stream.date }}

        </div>
        <div class="flex items-center sm:text-xs">
          <span class="anticon mr-2 relative" v-if="this.stream.date">
              <font-awesome-icon icon="clock" class="text-gray-300 text-xs"/>
          </span>
          {{ this.stream.hour }}
        </div>
        <div class="flex items-center ml-3">
          <span class="anticon mr-2 relative" >
            <font-awesome-icon icon="compact-disc"  />
          </span>
          <span class="font-bold sm:text-xs">
              <span class="text-blue-500" v-if="isRecording" >
               Yayın kayıt ediliyor
              </span>
              <span v-else>
                Yayın kayıt edilmiyor
              </span>
          </span>
        </div>
      </div>
      </a-spin>
  </div>

  <div id="video-container" v-if="this.preview || this.live" style="width: 100%; background-color: rgb(0, 0, 0);">
      <span id="live-video-icons" v-show="this.live">
        <font-awesome-icon id="live-icon"  icon="video" size="3x"  style="color: red" />
    </span>
    <canvas v-show="!this.isJoinStream"  id="preview"></canvas>
<!--    <video v-show="!this.isJoinStream"  v-if="!this.canvasFill"  id="local-video" src="" style="width: 100%;height:auto"></video>-->
    <div v-show="!this.isPlaying && this.isJoinStream "  style="color: #F2F2F2;font-size: 20px; text-align: center;padding: 30px;height: 56vh">
      <p >
        Yayın bekleniyor.
      </p>
      <p  style="color: #F2F2F2;font-size: 14px; text-align: center;padding: 30px;height: 56vh">
          Ön izlemedir gecikme yaşanabilir. OBS'den yayını başlattıktan sonra ürün ve kampanya göndermeyi deneyiniz aksi takdirde gönderimde hata oluşacaktır.
      </p>
    </div>
  </div>
  <div v-else  >
      <img ref="banner" style="width: 100%;height: auto" src="@/assets/img/login-right.png" alt="">
  </div>
  <div>
    <a-modal
        title="Yayın seçenekleri"
        :visible="visible"
        @cancel="this.visible =false"
        :footer="null"
    >
      <div>

        <p style="font-weight: bold;font-size: 15px;margin-bottom: 5px">Servis sağlayıcısı seçenekleri</p>

        <p>
          <input name="radio" type="radio" :checked="this.selectOnliveSaleOption" @click="selectOnliveSale" id="onlivesale" />
          <label for="onlivesale">
            Onlivesale web
          </label>

        </p>

        <p>
          <input name="radio" type="radio" @click="selectOBS" id="obs" />
          <label for="obs">
             OBS
            <a href="https://obsproject.com/tr/download" target="_blank">
              <font-awesome-icon icon="download" class="text-gray-300 text-xs ml-2"/>
            </a>
          </label>
        </p>
        <div v-show="selectObsOption" >
          <a-divider class="my-2" />

          <a-spin :spinning="keySpin">
              <strong>Host</strong> : {{ host }}
              <br>
              <strong>Stream key</strong> : {{ streamKey }}
          </a-spin>
          <a-divider class="my-2" />

          <strong>
            Notlar
          </strong>
          <p>
            1- OBS İle yayın nasıl açılır?
            <br>
            Obs'yi <a href="https://obsproject.com/tr/download" target="_blank">indirerek</a> işlemlere başlayalım. Kurulumu tamamladıktan sonra programı açalım ve
            sağ altta bulunan "Ayarlar" butonuna tıklayınız. Sol kısımdan "Yayın" sekmesine tıklayınız ve açılan formda Hizmet kısmını "özel" olarak işaretleyiniz. Dahasonra Sunucu
            yazan kısıma yukarıdan aldığımız Host bağlantısını yazınız. Yayın anahtarı kısmına ise yine yukarıdan aldığınız Stream Key anahtarını yazınız.
            Tamam butonuna tıklayıp sağ altta bulunan Yayını başlat butonuna tıklayınız.
            <strong>
              Yayın OBS'de başladıktan sonra bu pop-up'ta bulunan "Yayına katıl" butonuna tıklayarak devam ediniz.
            </strong>

            <br>
            2- Neden OBS ile yayın?
            <br>
            Profesyonel bir yayın için tercih edilebilir. Yayın kalitesini arttırmak için OBS kullanımı uygun bir seçenek olacaktır.
            Bunun yanısıra OBS ile yayına bir çok özellik eklememiz mümkün.

          </p>
        </div>
        <div v-show="!selectObsOption" >
          <a-divider class="my-2" />
          <strong>
            Medya seçimi
          </strong>
          <a-spin :spinning="loadingDevice">
            <p>Kamera:</p>
            <div class="w-full">
              <a-select  style="width: 100%" v-model:value="selectedVideoDevice" placeholder="Seçiniz" >

                <a-select-option  v-for="device  in  this.videoDevices" :key="device.deviceId" :value="device.deviceId">
                  {{ device.label == '' ? 'default': device.label }}
                </a-select-option>
              </a-select>
            </div>

            <p>Mikrofon:</p>

            <div class="w-full">
              <a-select  style="width: 100%" v-model:value="selectedAudioDevice"  placeholder="Seçiniz">
                <a-select-option  v-for="device  in  this.audioDevices" :key="device.deviceId" :value="device.deviceId">
                  {{ device.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-spin>
        </div>





      </div>
      <div v-show="this.selectOnliveSaleOption">
         <a-divider class="my-2" />

         <p style="font-weight: bold;font-size: 15px;margin-bottom: 5px">Sosyal medya seçenekleri</p>

         <p>
           <input  type="checkbox" id="instagram" disabled />
           <label for="instagram">
             İnstagram ile eş zamanlı yayın <a-badge count="Yakında !" />
             <font-awesome-icon icon="instagram" class="text-gray-300 text-xs ml-2"/>
           </label>
         </p>
      </div>
      <div >
        <a-divider class="my-2" />

        <p style="font-weight: bold;font-size: 15px;margin-bottom: 5px">Kayıt seçenekleri</p>

        <p>
          <input  type="checkbox" id="save-stream" v-model="recordStream" :disabled="!this.$store.state.useStorage" />
          <label for="save-stream">
            Yayını kaydet
          </label>
          <br>
          <small v-if="!this.$store.state.useStorage">
            Depolama paketiniz bulunamadı -
            <a @click="redirectPacket()">Paket listesi</a>
          </small>
        </p>
      </div>
      <hr class="my-2">
      <div class="flex justify-center">
        <button class="w-7/12 h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors" v-if="selectOnliveSaleOption" @click="switchPreview" >
          <font-awesome-icon icon="camera" class="mr-3" />
          Kamerayı aç
        </button>
        <button class="w-7/12 h-11 bg-blue-700 rounded-md text-white text-xs text-center hover:bg-blue-700 transition-colors" @click="joinStream"  v-else>
          <font-awesome-icon icon="play" class="mr-3" />
          Yayına katıl
        </button>
      </div>

    </a-modal>

  </div>
</template>

<script>
export default {
  name: "LiveScreen",
  data(){
    return{
      frameVisible:false,
      spinEventInfo:true,
      stream:[],
      live:false,
      preview:false,
      ws:{},
      mediaRecorder:{},
      visible: false,
      host: "",
      streamKey:"",
      keySpin:true,
      selectObsOption:false,
      selectOnliveSaleOption:true,
      isJoinStream : false,
      isPlaying :false,
      videoElement:{},
      player:{},
      interval:{},
      recordStream:false,
      isRecording:false,
      BroadcastClient: {},
      canvasFill:false,
      ingest_endpoint:"",
      stream_key:"",
      videoDevices:[],
      selectedVideoDevice: '',
      audioDevices:[],
      selectedAudioDevice: '',
      loadingDevice: true

    }
  },
  async created() {
    window.onbeforeunload = function(){
      return "Sayfadan ayrılırsanız yayın durdurulacaktır.";
    }
    let  id = this.$route.params.id;

    let url ='';
    let token = '';
    if (this.$store.state.layout !=='streamer'){
      url = '/eventForUserAndStreamer/'+ id
      token = localStorage.getItem("token")
    }else {
      url = '/streamer/eventForUserAndStreamer/'+ id
      token = localStorage.getItem("streamerToken")
    }

    this.$appAxios.get(url,{
      headers: {
        Authorization: [`Bearer ${token}`]
      }
    }).then((result)=>{
      this.stream.products = result.data.data.products;
      this.stream.title = result.data.data.title
      this.stream.liveDate = result.data.data.date;
      this.$store.state.liveVideo.playbackUrl = result.data.data.playback_url
      this.spinEventInfo = false

      try {
        let date = new Date(result.data.data.date);
        var options = { weekday: 'long',month:'long',day:'numeric'};
        this.stream.date = new Intl.DateTimeFormat('tr-TR', options).format(date)
        options = {hour:'numeric',minute:"numeric"};
        this.stream.hour = new Intl.DateTimeFormat('tr-TR', options).format(date)

      }catch (e) {
        this.stream.date  =''
        this.stream.hour = ''
      }


      this.$store.state.useStorage = result.data.data.use_storage
      if(result.data.data.image_urls !== undefined && result.data.data.image_urls!=="" ){

        if(result.data.data.image_urls !== null && (result.data.data.image_urls)[0]!== undefined ){
          this.$refs.banner.src = this.stream.image = (result.data.data.image_urls)[0];
        }
      }

      this.getEventInfo();
      setInterval(()=>{
          this.getEventInfo();
      },9000);
    });

  },
  unmounted(){
    //yayını durdurmak için bunu yapıyorum.
    //ilerleyen zamanlarda sayfa değişirken yayın devam edebilir.
    window.location.reload();
    try {
      clearInterval(this.interval)
    }catch (e){
      console.log(e)
    }
    this.removeVideo();
  },
  methods:{
    redirectPacket(){
      this.$router.push({path:"/packet/packet-list"})
    },
    copyLink(){
      navigator.clipboard.writeText('https://app.onlivesale.com/'+this.$route.params.id);
      // navigator.clipboard.writeText('https://app.onlivesale.com//event/'+this.$store.state.params.id);
    },
    removeVideo(){
      try {
        this.videoElement.remove()
      }catch (e){
        console.log(e)
      }
      try {
        const player = this.player.getIVSPlayer()
        player.delete()
      }catch (e){
        console.log(e)
      }
    },
    showModal() {
      this.listDevices();

      if (!this.$store.state.user.verified){
        this.$swal({
          icon:'warning',
          title:this.$t('auth.verifyEmail.verification-required-title'),
          text:this.$t('auth.verifyEmail.verification-required-text')
        })
        return
      }

      this.visible = true;
    },
    selectOnliveSale(){
      this.selectObsOption = false;
      this.selectOnliveSaleOption = true;
    },
    selectOBS(){

      this.selectObsOption = true;
      this.selectOnliveSaleOption = false;

      //'rtmps://'+data[2]+'.global-contribute.live-video.net:443/app/'+streamKey;
      //'/rtmp/'+res.data.data.ingest_endpoint+'/'+res.data.data.stream_key
      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/getEventLiveKeys'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/getEventLiveKeys'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.get(url+'?eventId='+this.$route.params.id,{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then((res)=>{
        this.host ='rtmps://'+res.data.data.ingest_endpoint+'.global-contribute.live-video.net:443/app/';
        this.streamKey = res.data.data.stream_key;
        this.keySpin = false;

      }).catch();

    },
    async setRecordStreamConfiguration(record){
      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/setRecordStreamConfiguration'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/setRecordStreamConfiguration'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.post(url,{
        eventId:this.$route.params.id,
        record:record
      },{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then((result)=>{
        this.isRecording = result.data.data.record
        this.$swal.close();

      }).catch(()=>{
        this.$swal.close();
        if (record){
          this.$swal.fire({
            title: 'Yayın Kaydetme İşleminde Hata',
            text: "Lütfen tekrar deneyiniz",
            showCancelButton: true,
            confirmButtonText: 'Tekrar dene'
          }).then((result) => {
            if (result.isConfirmed) {
              return this.setRecordStreamConfiguration(record)
            }
          })
        }

        // this.$swal.close();
      })
    },
    async joinStream(){
      this.isJoinStream = true
      this.live = true
      this.visible = false;
      let record = false;
      if(this.recordStream){
        record = true;
      }
      this.$swal.showLoading();
      await this.setRecordStreamConfiguration(record);

      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/startStream'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/startStream'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.get(url+'?id='+ this.$route.params.id,{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then(()=>{

      }).catch(() =>{
        this.$swal({
          icon: 'error',
          title: 'Yayın başlatılamadı',
          html: 'Lütfen tekrar deneyiniz'
        })
      })



      this.$loadScript("https://player.live-video.net/1.8.0/amazon-ivs-player.min.js")
          .then(() => {
            this.interval =  setInterval(() => {
              if (!this.isPlaying){
                this.player = {};
                this.playbackUrl = this.$store.state.liveVideo.playbackUrl;
                this.loadIvsPlayer();
              }

            }, 3000);
          })
          .catch(() => {
          });
    },
    loadIvsPlayer() {
      if(this.isPlaying){
        return false;
      }

      if (window.IVSPlayer.isPlayerSupported) {
        let PlayerState = {};
        PlayerState = window.IVSPlayer.PlayerState;
        let PlayerEventType = {};
        PlayerEventType = window.IVSPlayer.PlayerEventType
        this.player = window.IVSPlayer.create();
        this.removeVideo();

        this.videoElement = document.createElement('video')
        this.videoElement.id = 'stream-video'
        this.videoElement.controls = true
        this.videoElement.playsinline  = true
        this.videoElement.autoplay  = true
        this.videoElement.loop  = true
        this.videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
        this.videoElement.setAttribute('playsinline', 'playsinline');


        this.player.attachHTMLVideoElement(this.videoElement)

        this.player.addEventListener(PlayerState.PLAYING,  ()=> {
          console.log("Player State - PLAYING");
          // this.videoElement.style.maxHeight = "57vh";
          this.videoElement.style.width = "100%";
          this.isPlaying= true;
          this.$store.state.isPlaying= true;
          if (document.getElementById('video-container') !== undefined && document.getElementById('stream-video') === null){
            document.getElementById('video-container').appendChild(this.videoElement)
          }

        });
        this.player.addEventListener(PlayerState.ENDED, ()=> {
          console.warn("Player END:");
          this.isPlaying= false;
          this.$store.state.isPlaying= false;
          this.removeVideo();

          this.streamStatus = "Yayıncı yayını sonlandırdı."

        });
        this.player.addEventListener(PlayerState.READY, ()=> {
          console.log("Player State - READY");
        });
        this.player.addEventListener(PlayerEventType.ERROR,  ()=> {
          console.warn("Player Event - ERROR:");
          this.removeVideo();

        });

        this.player.load(this.playbackUrl);
        this.player.play();
      }
    },
    async switchPreview() {
      if(this.selectedVideoDevice == '' || this.selectedAudioDevice == ''){
        this.$swal({
          icon: 'error',
          title: 'Medya ayarlarını belirtiniz',
          html: 'Lütfen Medya Aygıtı Seçiniz'
        })
        return
      }
      let record = false;
      if(this.recordStream){
        record = true;
      }
      this.$swal.showLoading();

      this.preview = true
      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/getEventLiveInfo'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/getEventLiveInfo'
        token = localStorage.getItem("streamerToken")
      }
      await this.$appAxios.get(url+'?eventId='+this.$route.params.id+'&preview=true',{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then((res)=> {
        this.ingest_endpoint = res.data.data.ingest_endpoint;
        this.stream_key = res.data.data.stream_key;
        this.$loadScript("https://web-broadcast.live-video.net/1.1.0/amazon-ivs-web-broadcast.js")
            .then(() => {
              this.ivsClient();
            })
      });


      await this.setRecordStreamConfiguration(record);
      //kaydetmemesi içinde configurasyon gerekli


      this.preview = true
      this.visible=false
      this.isJoinStream = false


    },
    async listDevices(){
      this.loadingDevice = true

      let permissions = {
        audio: false,
        video: false,
      };
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        for (const track of stream.getTracks()) {
          track.stop();
        }
        permissions = { video: true, audio: true };
      } catch (err) {
        permissions = { video: false, audio: false };
        console.error(err.message);
      }
      // If we still don't have permissions after requesting them display the error message
      if (!permissions.video) {
        console.error('Failed to get video permissions.');
        this.$swal({
          icon: 'error',
          title: 'İzin hatası',
          html: 'Lütfen Tarayıcınızın kamera izinleriniz kontrol ediniz.'
        })
      } else if (!permissions.audio) {
        console.error('Failed to get audio permissions.');
        this.$swal({
          icon: 'error',
          title: 'İzin hatası',
          html: 'Lütfen Tarayıcınızın mikrofon izinleriniz kontrol ediniz.'
        })
      }


      const devices =  await navigator.mediaDevices.enumerateDevices();
      console.log(devices)
      this.videoDevices = devices.filter((d) => d.kind === 'videoinput');
      this.audioDevices = devices.filter((d) => d.kind === 'audioinput');
      this.loadingDevice = false


    },
    ivsClient(broadcast = false){

      let client = window.IVSBroadcastClient.create({
        streamConfig: window.IVSBroadcastClient.BASIC_LANDSCAPE,
        ingestEndpoint:  'rtmps://'+this.ingest_endpoint+'.global-contribute.live-video.net:443/app/',
      });


        // Start function
        const record = async () => {
          // const streamConfig = window.IVSBroadcastClient.BASIC_LANDSCAPE;
          window.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: this.selectedVideoDevice,
            },
          });
          client.addVideoInputDevice(window.cameraStream, 'camera1', { index: 0 }); // only 'index' is required for the position parameter


          window.microphoneStream = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: this.selectedAudioDevice },
          });
          client.addAudioInputDevice(window.microphoneStream, 'mic1'); // only 'index' is required for the position parameter


          const previewEl = document.getElementById('preview');
          client.attachPreview(previewEl);
          if (broadcast){
            client.startBroadcast(this.stream_key)
                .then(() => {
                  this.live = true
                  console.log('I am successfully broadcasting!');
                }).catch((error) => {
              this.live = false
              console.error('Something drastically failed while broadcasting!', error);
            });
          }

        }

        // Call start
        record();

      this.BroadcastClient = client;
    },
    setLocalStream(stream){

      let video = document.getElementById("local-video");
      video.srcObject = stream;
      video.muted = true;
      video.play();

    },
    stopStream(){
      this.live = false;
      this.BroadcastClient.stopBroadcast();
      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/stopStream'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/stopStream'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.get(url+'?id='+this.$route.params.id, {
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then(()=>{
        this.$swal({
          icon: 'success',
          title: "yayın başarıyla durduruldu"
        })
      })

    },
    goLive(){
      this.preview = true

      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/getEventLiveInfo'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/getEventLiveInfo'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.get(url+'?eventId='+this.$route.params.id,{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then((res)=> {
        this.ingest_endpoint = res.data.data.ingest_endpoint;
        this.stream_key = res.data.data.stream_key;
        this.$loadScript("https://web-broadcast.live-video.net/1.1.0/amazon-ivs-web-broadcast.js")
            .then(() => {
              this.ivsClient(true);
            })
      });


    },
    getEventInfo(){
      let url ='';
      let token = '';
      if (this.$store.state.layout !=='streamer'){
        url = '/getEventStatistics'
        token = localStorage.getItem("token")
      }else {
        url = '/streamer/getEventStatistics'
        token = localStorage.getItem("streamerToken")
      }
      this.$appAxios.get(url+'/'+this.$route.params.id,{
        headers: {
          Authorization: [`Bearer ${token}`]
        }
      }).then((res)=>{


        this.$store.state.totalClickForHeader = res.data.data.totalClick;
        this.$store.state.totalViewerForHeader = res.data.data.totalViewer;
        this.$store.state.singularLikeForHeader = res.data.data.singularLike;
        this.$store.state.totalCommentForHeader = res.data.data.totalComment;
        this.$store.state.totalLikeForHeader = res.data.data.totalLike;
        // this.$store.state.totalCreditForHeader =parseFloat(res.data.data.credit).toFixed(2);
        this.$store.state.totalCreditForHeader =(res.data.data.credit);


        res.data.data.products.forEach((data)=>{
          this.$store.state.userProducts.forEach((value)=>{
            if (value.id==data.id){
              value.statistics.click = data.click
            }
          })
        })
        res.data.data.stocks.forEach((data)=>{
          this.$store.state.userProducts.forEach((value)=>{
            if (value.product_id==data.productId){
              value.statistics.stock = data.stock
            }
          })
        })

      })
    }
  }
}
</script>

<style scoped>
#local-video{
  border-radius: 10px !important;
  width: 100%;
}
#video-container{
  position: relative;
}
#live-video-icons{
  position: absolute;
  top: 10px;
  right: 10px;
}

#video-container canvas {
    margin: 0 auto;
}

@media screen and (max-width:768px) {
    #video-container canvas {
        width: 100%;
        margin-top: 20px;
    }
}

</style>