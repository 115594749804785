<template>
    <div class="md:w-3/5 sm:w-full">
        <LiveScreen />
    </div>
    <div class="md:w-1/5 sm:w-full px-2.5 hidden md:block">
        <Products />
        <Campaign />
    </div>
    <div class="md:w-1/5 sm:w-full px-2.5 hidden md:block">
        <Chat />
    </div>

    <a-tabs v-model:activeKey="activeKey" class="w-full block md:hidden live-tabs">
        <a-tab-pane key="1">
            <template #tab>
                <span class="text-sm font-medium ml-3">{{ $t('customer.liveSale.chatBlock.chat') }}</span>
            </template>
            <Chat />
        </a-tab-pane>
        <a-tab-pane key="2">
            <template #tab>
                <span class="text-sm font-medium ml-3">{{ $t('user.live.productBlock.products') }}</span>
            </template>
            <Products />
        </a-tab-pane>
        <a-tab-pane key="3">
            <template #tab>
                <span class="text-sm font-medium ml-3">{{$t('user.live.campaignBlock.campaigns')}}</span>
            </template>
            <Campaign />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import Products from "@/components/userLive/Products";
import LiveScreen from "@/components/userLive/LiveScreen";
import Chat from "@/components/live/Chat";
import Campaign from "@/components/userLive/Campaign";

export default {
    components: {
        Campaign,
        Products,
        LiveScreen,
        Chat,
    },
    data() {
        return {
            activeKey: "1",
        }
    },
    created() {},
};
</script>

<style>
    .live-tabs .card-top {
        display: none;
    }
</style>
